<template>
  <div
    class="d-flex justify-center align-center"
  >
    <VueSvgGauge
      id="gauge-chart-output"
      :start-angle="-90"
      :end-angle="90"
      :value="value"
      :separator-step="0"
      :min="properties.min"
      :max="properties.max"
      :gauge-color="[{ offset: 0, color: properties.low_color}, { offset: 100, color: properties.high_color}]"
      :scale-interval="properties.interval"
      :inner-radius="80"
    >
      <div
        id="inner-text"
        :style="gaugeStyle"
        class="d-flex justify-center align-center font-weight-bold"
      >
        <div class="text-value text-center">
          <template v-if="properties.show_value_unit">
            <div
              v-if="energyData"
              class="small text-muted"
            >
              <v-icon color="warning">
                mdi-flash
              </v-icon>
              <!-- {{ $options.filters.parsePayloadKey(data.payload_keys).unit || null }} -->
            </div>
            <div
              v-else
              class="small text-muted"
            >
              {{ underscoreToSpace(data.payload_keys[0]) }}
              <!-- {{ $options.filters.parsePayloadKey(data.payload_keys[0]).unit || null }} -->
            </div>
          </template>
          <div class="m-0 p-0">
            {{ value }}
          </div>
        </div>

        <span id="min-value">{{ properties.min }}</span>
        <span id="max-value">{{ properties.max }}</span>
      </div>
    </VueSvgGauge>
  </div>
</template>

<script>
import { VueSvgGauge } from 'vue-svg-gauge';
import { floatPrecision, underscoreToSpace } from '../../../utils/utils';
import WidgetMixin from '../../../mixins/WidgetMixin';


export default {
  name: 'GaugeWidget',
  components: { VueSvgGauge },
  mixins: [WidgetMixin],

  computed: {
    energyData() {
      if (this.data.source === 'energy' && this.deviceData.payloads.length) {
        return this.deviceData.payloads[0];
      }
      return null;
    },

    value() {
      if (this.energyData) {
        return Number(floatPrecision(this.energyData.cumulative_value, 2));
      }
      if (this.deviceData.latest_payload) {
        return Number(floatPrecision(this.deviceData.latest_payload[this.data.payload_keys], 2));
      }
      return 0;
    },

    gaugeStyle() {
      return {
        '--font-size': `${this.properties.size}px`,
      };
    },
  },

  methods: {
    underscoreToSpace,
  },

};
</script>


<style lang="scss" scoped>
#gauge-chart-output {
  position: absolute;
  height: 80%;
  width: 80%;
  bottom: 15%;
}
#inner-text {
  // allow the text to take all the available space in the svg on top of the gauge
  height: 100%;
  width: 100%;
  .text-value {
    position: absolute;
    bottom: 10%;
    font-size: var(--font-size);
  }

  span#min-value {
    position: absolute;
    left: 23px;
    bottom: -4px;
    font-size: 0.8rem;
    color: grey;
  }

  span#max-value {
    position: absolute;
    right: 23px;
    bottom: -4px;
    font-size: 0.8rem;
    color: grey;
  }
}
</style>
